console.log('fdfsdfsdfs');
$('.js-form-addphoto').each(function () {

    var $button = $(this),
        $photoElement = $('.js-photo'),
        i = 2;

    $button.on('click', function () {
        console.log($photoElement);
        $photoElement.clone().appendTo('.js-clone').find('.input__field').prop('name', 'foto' + i).val('');
        i++;
    });
});

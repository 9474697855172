import flatpickr from "flatpickr";
import { Dutch } from "flatpickr/dist/l10n/nl.js";

function disableMonAndSunday(date) {
    //Disable if day is monday or sunday
    return (date.getDay() === 0 || date.getDay() === 1);
}

function disableVacationDates(date) {
    const rdatedData = ["2022-08-08",
        "2022-08-09",
        "2022-08-10",
        "2022-08-11",
        "2022-08-12",
        "2022-08-13",
        "2022-08-14",
        "2022-08-15",
        "2022-08-16",
        "2022-08-17",
        "2022-08-18",
        "2022-08-19",
        "2022-08-20",
        "2022-08-21",
        "2022-08-22",
        "2022-08-23",
        "2022-08-24",
        "2022-08-25",
        "2022-08-26",
        "2022-08-27",
        "2022-08-28"];
    return rdatedData.includes(date.toISOString().substring(0, 10));
}

flatpickr('.flatpicker', {
    locale: Dutch,
    dateFormat: "d-m-Y",
    minDate: new Date(),
    disable: [disableMonAndSunday, disableVacationDates],
});

flatpickr('.flatpicker-time', {
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    time_24hr: true,
    minTime: "10:00",
    maxTime: "16:00",
});

var datePicker = $('.js-datepicker');
datePicker.on('focus', ({ currentTarget }) => $(currentTarget).blur())
datePicker.prop('readonly', false)

// Base stylesheet needed for every page
import './scss/default.scss';
// IMPORT FUNCTION
const importAll = (r) => r.keys().forEach(r);

// INCLUDING DEPENDENCIES
require('jquery');
require('cookieconsent');
require('slick-carousel');
require('lightgallery');
require('lightgallery/modules/lg-fullscreen');
require('lightgallery/modules/lg-pager');
require('lightgallery/modules/lg-zoom');
require('flatpickr');

// INCLUDING SCRIPTS
const scripts = require.context('./scripts', true, /^.*\.js$/);
$(document).ready(() => {
    importAll(scripts);
});

// INCLUDING IMAGES
const images = require.context('./images', true,  /\.(png|jpe?g|gif)$/);
importAll(images);

Object.values(document.getElementsByClassName('js-dualblock-container')).map(dualblockContainers => {
    const dualblocksNav = dualblockContainers.getElementsByClassName('js-dualblock-container-nav')[0];
    const dualblocks = Object.values(dualblockContainers.getElementsByClassName('js-dualblock'));

    dualblocks.map((dualblock) => {
        const dualblockHeading = dualblock.dataset.heading;
        const navItem = document.createElement('li');

        navItem.innerText = dualblockHeading;

        navItem.addEventListener('click', () => {
            const bounds = dualblock.getBoundingClientRect();

            window.scrollBy({top: bounds.top - 160, behavior: 'smooth'});
        });

        dualblocksNav.appendChild(navItem);
    });

    const dualblocksNavItems = dualblocksNav.childNodes;

    window.addEventListener('scroll', () => {
        const scrollTop = window.scrollY;
        const scrollLeft = dualblocksNav.scrollLeft;
        let foundCurrentDualblock = false;

        dualblocks.map((dualblock, index) => {
            const navItem = dualblocksNavItems[index];

            if(!foundCurrentDualblock && (dualblock.offsetTop + dualblock.clientHeight - 161) >= scrollTop) {
                foundCurrentDualblock = true;
                navItem.classList.add('current');

                if(!(navItem.offsetLeft >= scrollLeft && navItem.offsetLeft <= scrollLeft)) {
                    dualblocksNav.scrollTo({behavior: 'smooth', left: navItem.offsetLeft})
                }
            } else {
                navItem.classList.remove('current');
            }
        });
    });
});
$('.js-cookieconsent').each((i, element) => {
    const $element = $(element);
    const data = $element.data('cc');

    cookieconsent.initialise({
        'position': data.position,
        'content': {
            'message': data.message,
            'dismiss': data.buttonText,
            'link': data.moreText,
            'href': data.moreLink
        }
    });

    $element.remove();
});
import zilvercms from './_utils';

$('.js-nav').each((i, element) => {
    const $nav = $(element);
    const $body = $('body');
    const $trigger = $('.js-nav-trigger');
    const $overlay = $nav.find('.js-nav-overlay');

    $trigger.on('click', () => {
        if($body.hasClass('nav--open')) {
            zilvercms.unlockBody();
            $body.removeClass('nav--open');
        } else {
            $body.addClass('nav--open');
            zilvercms.lockBody();
        }
    });
});
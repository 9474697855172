var widgetCatalogue = new DICKSON.WidgetCatalogue(jQuery("#widget-showroom-catalogue"),{
    key: "Xl06EEbD7BI1idw2KkMr8boEXnVMq3RrseIiNTislDuqiqh6daLgN8RB6FZC+2BswlaYuHB4u6SmGIWvPeLeZGBS98We0D9ayGA154H+2F3Z7Xk+3D",
    lang: "nl",
    display:{
        search: true,
        filters: true,
        filters_list: ["ranges","colors","uses","patterns"],
        range_title: true,
        product_sheet: true,
        blocs_product: ["applications","description","caracteristiques","lumiere_confort","labels","garanties","fichetechnique"],
        order_sample: true,
        previous_products: true,
    },
    style:{
        thumbnails_width: "medium",
        thumbnails_style: "stroke",
        thumbnails_hover: true,
        colors:{
            widget_bkg_color: "#FFF",
            widget_color: "#222",
            selection_bkg_color: "#F2F2F2",
        },
    },
});


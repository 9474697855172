const zilvercms = {};

zilvercms.getMetaValue = key => {
    const metaTag = document.querySelector('meta[name="zilvercms:'+ key +'"]');

    if(metaTag !== null) {
        return metaTag.getAttribute('content');
    }

    return '';
};

zilvercms.variables = {
    cdnPrefix: zilvercms.getMetaValue('cdnPrefix'),
    currency: {
        showSymbol: !!parseInt(zilvercms.getMetaValue('currencyShowSymbol')),
        symbol: zilvercms.getMetaValue('currencySymbol'),
        leadingSymbol: !!parseInt(zilvercms.getMetaValue('currencyLeadingSymbol')),
        separator: zilvercms.getMetaValue('currencySeparator'),
        zerosAsDash: zilvercms.getMetaValue('currencyZerosAsDash')
    },
    sizes: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']
};

zilvercms.imageHelper = (url, options, quality) => {
    url = !!url ? url : '';
    options = !!options ? options : '100x100';
    quality = !!quality ? quality : 80;

    let prefix = zilvercms.variables.cdnPrefix;
    prefix = prefix.replace('###', options);
    prefix = prefix.replace('QQQ', quality);

    return prefix + url;
};

zilvercms.currencyHelper = amount => {
    let value = (amount / 100).toFixed(2);
    const separator = zilvercms.variables.currency.separator;

    value = value.replace('.', separator);

    if(zilvercms.variables.currency.showSymbol) {
        const symbol = zilvercms.variables.currency.symbol;

        value = zilvercms.variables.currency.leadingSymbol ? symbol + ' ' + value : value + ' ' + symbol;
    }

    if(zilvercms.variables.currency.zerosAsDash === 1) {
        value = value.replace((separator + '00'), (separator + '-'));
    }

    return value;
};

zilvercms.translationHelper = (key, variables) => {
    if(typeof TRANSLATIONS !== 'undefined' && typeof TRANSLATIONS[key] !== 'undefined') {
        let translation = TRANSLATIONS[key];

        if(typeof variables === 'object') {
            for(const variable in variables) {
                translation = translation.replace(variable, variables[variable]);
            }
        }

        return translation;
    }

    return key;
};

zilvercms.lockBody = () => {
    const $body = $('body'),
        scrollTop = $(window).scrollTop();

    $body.addClass('locked');

    if(scrollTop === 0) {
        $body.addClass('locked--on-top');
    }

    $body.css({ 'position': 'fixed', 'top': (0 - scrollTop), 'width': '100%' }).data('scrollTop', scrollTop);
};

zilvercms.unlockBody = () => {
    const $body = $('body');

    $body.removeClass('locked locked--on-top');

    $body.removeAttr('style');
    $(window).scrollTop($body.data('scrollTop'));
};

zilvercms.viewport = {
    is: () => window.getComputedStyle(document.body, ':after').content.replace(/"/g, ''),
    isEqualOrGreaterThan: size => this.getIndex(this.is()) >= this.getIndex(size),
    isGreaterThan: size => this.getIndex(this.is()) > this.getIndex(size),
    isEqualOrSmallerThan: size => this.getIndex(this.is()) <= this.getIndex(size),
    isSmallerThan: size => this.getIndex(this.is()) < this.getIndex(size),
    getIndex: size => {
        const number = zilvercms.variables.sizes.indexOf(size);
        if (number === -1) throw 'no valid viewport name given';
        return number;
    }
};

export default zilvercms;

$('.js-productimages').each((i, element) => {
    const $productimages = $(element);
    const $productimagesSelector = $productimages.find('.js-productimages-selector');

    $productimages.lightGallery({
        mode: 'lg-fade',
        download: false,
        pager: true,
        selector: $productimagesSelector
    });
});

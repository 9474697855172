$('.js-header').each((i, element) => {
    const $header = $(element);

    $(window).on('scroll', () => {
        const windowTop = $(window).scrollTop();
        const $headerLogo = $header.find('.js-header-logo');

        if(windowTop > $header.outerHeight()) {
            $header.addClass('header--sticky');
            $headerLogo.addClass('header__logo--small');
        } else {
            $header.removeClass('header--sticky');
            $headerLogo.removeClass('header__logo--small');
        }
    })

    $('.js-header').on('click','.js-search-trigger', function(e) {
        e.preventDefault();
    
        // Hide search trigger
        $('.js-search-space').html('');
        $('.js-search-space').css('width','15rem');

        // Open search input
        const searchField = $('.js-search-field');
        searchField.fadeIn('fast');
        searchField.find('.js-search-input').focus();
        $(e.currentTarget).on('click', function(e) {
            $('.js-search-submit').submit();
        })

    })
});
$('.js-reviews').each((i, element) => {
    const $reviews = $(element);
    const $main = $reviews.find('.js-reviews-main');
    const $images = $reviews.find('.js-reviews-images');

    const $prev = $reviews.find('.js-reviews-prev');
    const $next = $reviews.find('.js-reviews-next');

    $main.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        dots: true,
        dotsClass: 'reviews__dots',
        asNavFor: '.js-reviews-images',
    });

    $images.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        fade: true,
        arrows: true,
        prevArrow: $prev,
        nextArrow: $next,
        dots: false,
        asNavFor: '.js-reviews-main'
    });
});